<div *ngIf="broadcast">
  <app-page-title icon="fa-bullhorn" title="Envio em Massa">
    <a [routerLink]="['/broadcasts/new']" class="btn btn-info btn-icon-right">
      Criar Envio em Massa <i class="fa fa-paper-plane-o"></i>
    </a>
  </app-page-title>

  <div class="card gradient-row primary" *ngIf="broadcast?.id && isImporting">
    Os Leads deste Broadcast estão sendo importados. Assim que finalizar você
    será notificado.
  </div>

  <div class="row mb-2">
    <div class="col">
      <div class="form-group">
        <label>Campanha:</label>
        <input type="text" class="form-control" [value]="broadcast.name" readonly />
      </div>
    </div>

    <div class="col">
      <div class="form-group">
        <label>Data/Hora do Envio:</label>
        <input type="text" class="form-control" [value]="broadcast.scheduled_date | date : 'dd/MM/yyyy, H:mm'"
          readonly />
      </div>
    </div>

    <div class="col">
      <div class="form-group">
        <label>Status:</label>
        <input type="text" class="form-control" [value]="broadcast.broadcast_status_id | broadcastStatus" readonly />
      </div>
    </div>

    <div class="col" *ngIf="broadcast.short_url">
      <div class="form-group">
        <label>Total clicks:</label>
        <input type="text" class="form-control" [value]="broadcast.clicks" readonly />
      </div>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-md-12" *ngIf="broadcast.leadList">
      <div class="form-group">
        <label>Lista:</label>
        <input type="text" class="form-control" [value]="broadcast.leadList.name" readonly />
      </div>
    </div>
  </div>

  <div class="form-group mb-2">
    <label>Mensagem:</label>
    <textarea class="form-control" readonly rows="3">{{
      broadcast.message
    }}</textarea>
  </div>

  <div class="row mb-2">
    <div class="col-md-3">
      <div class="form-group">
        <label>Total de leads:</label>
        <input type="text" class="form-control" [value]="broadcast.leads_count" readonly />
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Total enviado:</label>
        <input type="text" class="form-control" [value]="broadcast.total_sent" readonly />
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Total de erros:</label>
        <input type="text" class="form-control" [value]="broadcast.total_errors" readonly />
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Total aguardando envio:</label>
        <input type="text" class="form-control" [value]="
            broadcast.leads_count -
            broadcast.total_sent -
            broadcast.total_errors
          " readonly />
      </div>
    </div>
  </div>

  <div class="row" *ngIf="broadcast.url">
    <div class="col form-group">
      <label>URL:</label>
      <input type="text" class="form-control" readonly id="url" [value]="broadcast.url" (click)="copyUrl()" />
    </div>
    <div class="col form-group">
      <label>URL Curta:</label>
      <input type="text" class="form-control" readonly id="short_url" [value]="broadcast.short_url"
        (click)="copyShortUrl()" />
    </div>
  </div>

  <div class="mt-2" *ngIf="broadcast.reason">
    <label>Motivo do cancelamento</label>
    <textarea class="form-control" readonly rows="2">{{
      broadcast.reason
    }}</textarea>
  </div>

  <div class="d-flex align-items-center mb-4 pt-3">
    <a [routerLink]="['/broadcasts', broadcast.id]" class="btn btn-primary me-2" *ngIf="isScheduled">
      Alterar
    </a>

    <a [routerLink]="['/broadcasts']" class="btn btn-secondary">
      Voltar
    </a>

    
    <label class="ms-3">Filtrar por Telefone: </label>
    <div class="input-group ms-3" style="max-width: 365px;">
      <input type="text" class="form-control" placeholder="(99) 99999-9999" [(ngModel)]="phone" name="phone" (keyup.enter)="filterByPhone()">
      <button class="btn btn-primary" (click)="filterByPhone()">
        <i class="fa fa-search"></i>
      </button>
    </div>
    

    <button *ngIf="TypeNumber" type="button" class="btn btn-secondary ms-lg-2" (click)="toggleTextarea()">Verificar
      Contatos</button>

  </div>
  <div *ngIf="showTextarea" class="textarea-container">
    <textarea #scrollContainer class="form-control" rows="8" [(ngModel)]="visibleContent" readonly>
    </textarea>
    <button type="button" class="btn btn-secondary mt-2 mb-2" (click)="toggleTextarea()">Fechar</button>
  </div>

  <table class="table table-hover elevated">
    <thead>
      <tr>
        <th>Telefone</th>
        <th>Mensagem</th>
        <th>Data Envio</th>
        <th>Status</th>
        <th *ngIf="isAdministrator">Provedor</th>
        <th *ngIf="isAdministrator">Status Provedor</th>
      </tr>
    </thead>
    <tbody *ngIf="contacts && contacts.data.length > 0; else notFound">
      <tr *ngFor="let contact of contacts.data">
        <td>{{ contact.phone }}</td>
        <td>{{ contact.message || "-" }}</td>
        <td>
          <span *ngIf="contact.sent_date">{{
            contact.sent_date | date : "dd/MM/yyyy, H:mm"
            }}</span>
          <span *ngIf="!contact.sent_date">-</span>
        </td>
        <td>
          {{ getStatus(contact) }}
        </td>
        <td *ngIf="isAdministrator">
          {{ providerAccountsNames[contact.provider_account_id] ?? 'Não fornecido' }}
        </td>
        <td *ngIf="isAdministrator">
          {{ contact.webhook_status ? getProviderStatus(contact.webhook_status) : 'Não fornecido' }}
        </td>
      </tr>
    </tbody>
  </table>

  <app-pagination *ngIf="contacts" [currentPage]="contacts.current_page" [itemsPerPage]="contacts.per_page"
    [totalItems]="contacts.total" (pageChanged)="pageChanged($event)">
  </app-pagination>

<ng-template #notFound>
  <tbody>
    <tr *ngIf="contacts">
      <td colspan="5">
        {{isArchived ? "Seus contatos foram arquivados." : "Nenhuma mensagem enviada"}}
      </td>
    </tr>
  </tbody>
</ng-template>
