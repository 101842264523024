import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PENDING_REVIEW, APPROVED, CANCELLED, SequenceStatus } from 'src/app/models/SequenceStatus';
import { User } from 'src/app/models/User';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ADMINISTRATOR, UserService } from 'src/app/shared/services/user.service';
import { LoadingService } from '../../shared/components/loading/loading.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SequencesService } from 'src/app/shared/services/sequences.service';
import { Sequence, SequenceValidationFilter } from 'src/app/models/Sequence';
import { SequenceStatusPipe } from 'src/app/shared/pipes/SequenceStatus.pipe';

@Component({
  selector: 'app-sms-validation',
  templateUrl: './sequences-validation.component.html',
  styleUrls: ['./sequences-validation.component.scss']
})
export class SequencesValidationComponent implements OnInit, OnDestroy {
  @ViewChild('modalSequence', { static: true }) modalSequence: ElementRef<any>;
  @ViewChild('modalAccept', { static: true }) modalAccept: ElementRef<any>;

  @Input() form: UntypedFormGroup;

  reason: string;
  sequence: Sequence;
  startDate: Date;
  endDate: Date;

  invalidTime: boolean;
  autoRefreshController = null;

  page = 1;
  pageSize = 10;
  originalUser: User;
  user: User;
  users: User[];

  filteredUsers: User[];

  loading: boolean;
  result: PaginatedResult<Sequence[]>;
  subscriptions: Subscription[] = [];

  @ViewChild('modalValidation')
  modalValidation: TemplateRef<any>;
  modalValidationRef: any;

  filterForm: UntypedFormGroup;

  get administrator(): boolean {
    return this.originalUser.profile_id === ADMINISTRATOR;
  }

  constructor(
    private sequenceService: SequencesService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private authService: AuthService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private loadginService: LoadingService
  ) { }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      text: [''],
      author: [''],
      orderDirection: ['DESC'],
      pendingOnly: [true]
    });


    this.user = this.authService.getUser();
    this.userService.listAll().subscribe((users) => {
      this.users = users;
      this.filteredUsers = users;
    });

    this.filterForm.get('author').valueChanges.subscribe(value => {
      if (!value) return this.filteredUsers = this.users;
      const lowerCaseValue = value.toLowerCase();
      this.filteredUsers = this.users.filter(user => {
        return user.email.toLowerCase().includes(lowerCaseValue) || user.name.toLowerCase().includes(lowerCaseValue) || user.id.includes(lowerCaseValue) || user.phone?.toString().includes(value);
      })
    })

    this.route.queryParams.subscribe(params => {
      this.page = params.page || this.page;
      this.pageSize = params.per_page || this.pageSize;

      this.filterForm.patchValue(params);

      if (params.startDate) {
        this.startDate = new Date(`${params.startDate} 00:00:00`);
        this.filterForm.patchValue({ startDate: this.startDate });
      } else {
        this.startDate = null;
        this.filterForm.patchValue({ startDate: null });
      }

      if (params.endDate) {
        this.endDate = new Date(`${params.endDate} 23:59:59`);
        this.filterForm.patchValue({ endDate: this.endDate });
      } else {
        this.endDate = null;
        this.filterForm.patchValue({ endDate: null });
      }

      this.loading = true;
      this.listPaginated();
    });

    this.subscriptions.push(
      this.authService.userChanged.subscribe(() => this.listPaginated())
    );

    this.autoRefreshController = setInterval(() => this.listPaginated(), 10 * 1000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());

    if (this.autoRefreshController) {
      clearInterval(this.autoRefreshController);
      this.autoRefreshController = null;
    }
  }

  formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  listPaginated(): void {
    const filter: any = { ...this.filterForm.value };

    this.user = this.authService.getUser();
    if (this.user && !this.user.validated && !this.administrator) {
      return;
    }

    this.originalUser = this.authService.getUser(true);

    let formattedFilter: SequenceValidationFilter = {
      start_date: null,
      end_date: null,
      pending_only: filter.pendingOnly,
      order_direction: filter.orderDirection,
      author: filter.author
    }

    if (filter.startDate) {
      formattedFilter.start_date = this.formatDate(filter.startDate);
    }

    if (filter.endDate) {
      formattedFilter.end_date = this.formatDate(filter.endDate);
    }
  
    if (this.loading) {
      this.loadginService.start();
    }

    this.subscriptions.push(
      this.sequenceService
        .getAllSequencesToValidate(this.page, this.pageSize, formattedFilter)
        .pipe(finalize(() => {
          this.loading = false;
          this.loadginService.stop();
        }))
        .subscribe(result => this.result = result)
    );


    this.filterForm.get('startDate').valueChanges.subscribe(() => this.validatePeriod());
    this.filterForm.get('endDate').valueChanges.subscribe(() => this.validatePeriod());
  }

  validatePeriod(): void {
    const startDate = this.filterForm.get('startDate').value;
    const endDate = this.filterForm.get('endDate').value;

    this.invalidTime = false;
    if (startDate && endDate) {
      this.invalidTime = startDate > endDate;
    }
  }

  pageChanged(page: any): void {
    const queryParams: any = { page: page || this.page, pageSize: this.pageSize, ...this.filterForm.value };

    if (queryParams.startDate) {
      queryParams.startDate = queryParams.startDate.toISOString().split('T')[0];
    }

    if (queryParams.endDate) {
      queryParams.endDate = queryParams.endDate.toISOString().split('T')[0];
    }

    this.listPaginated();
  }

  isPendingReview(sequence: Sequence): boolean {
    return sequence.status_id === PENDING_REVIEW;
  }

  approve(sequence: Sequence): void {
    this.sequenceService.approveSequence(sequence.id).subscribe(() => {
      this.toastService.success('Sequência aprovada com sucesso!');
      this.listPaginated();
    });
  }

  togglePending(): void {
    const pendingNow = this.filterForm.get('pendingOnly').value;

    this.filterForm.patchValue({ pendingOnly: !pendingNow });
    this.listPaginated();
  }

  getUser(sequence: Sequence): string {
    return sequence.campaign.user ? `${sequence.campaign.user.name} (${sequence.campaign.user.email})` : 'Desconhecido';
  }

  getMessage(sequence: Sequence): string {
    let message = sequence.text;
    if (message.includes('{meu_link}') && (sequence.short_url || sequence.url)) {
      const myLink = sequence.short_url || sequence.url;
      message = message.replace('{meu_link}', myLink);
    }

    return message;
  }

  cancel(sequence: Sequence): void {
    this.reason = '';
    this.sequence = sequence;
    this.modalValidationRef = this.modalService.open(this.modalValidation, { size: 'lg' });
  }

  disapprove(): void {
    if (!this.reason || this.reason.trim().length === 0) {
      this.toastService.error('Justificativa é obrigatória!');
      return;
    }

    this.sequenceService.disapproveSequence(this.sequence.id, this.reason).subscribe({
      next: () => {
        this.toastService.success('Sequência cancelada com sucesso!');
        this.listPaginated();
      },
      error: () => this.toastService.error('Erro ao cancelar a Sequência!'),
      complete: () => this.modalValidationRef.dismiss()
    });
  }
}
