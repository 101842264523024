import { Pipe, PipeTransform } from '@angular/core';
import { PENDING_REVIEW, APPROVED, CANCELLED } from "../../models/SequenceStatus";

@Pipe({
  name: 'sequenceStatus'
})
export class SequenceStatusPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case PENDING_REVIEW:
        return 'Pendente Análise';
      case CANCELLED:
        return 'Cancelada pelo Administrador';
      case APPROVED:
        return 'Aprovada';
    }
  }
}
