<app-page-title icon="fa-bullhorn" title="Validação de SMS Massivo"></app-page-title>
<form [formGroup]="filterForm" (submit)="pageChanged(page)">
  <div class="row col-md-12">
    <div class="col-md-12 col-sm-12" style="width: fit-content">
      <mat-form-field>
        <mat-label>Data inicial</mat-label>
        <input matInput readonly [matDatepicker]="pickerStart" name="startedMonth" formControlName="startDate" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerStart" />
        <mat-datepicker #pickerStart></mat-datepicker>
        <small class="text text-danger" *ngIf="invalidTime">
          Período inválido.
        </small>
      </mat-form-field>
    </div>
    <div class="col-md-12 col-sm-12" style="width: fit-content">
      <mat-form-field>
        <mat-label>Data final</mat-label>
        <input matInput readonly [matDatepicker]="pickerEnd" name="endedMonth" formControlName="endDate" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerEnd" />
        <mat-datepicker #pickerEnd></mat-datepicker>
        <small class="text text-danger" *ngIf="invalidTime">
          Período inválido.
        </small>
      </mat-form-field>
    </div>
  </div>
  <div class="row col-md-12 col-sm-12 flex-wrap">
    <div class="row mb-2">
      <div class="col-md-12 col-xs-12">
        <mat-form-field appearance="fill" class="mat-form-field-custom" style="width: 100%;">
          <mat-label>Usuário</mat-label>
          <input type="text" matInput formControlName="author" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let user of filteredUsers" [value]="user.id">
              {{user.name}} - {{ user.email }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <app-switcher display="block" label="Somente pendentes de análise"
          [checked]="filterForm.get('pendingOnly').value" (change)="togglePending()"></app-switcher>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <button class="btn btn-primary" type="submit" [disabled]="invalidTime">Pesquisar</button>
        <button class="btn btn-secondary ms-2" (click)="filterForm.reset(); pageChanged(1)">Limpar</button>
      </div>
    </div>
    <div class="row mb-5">
      <hr class="mt-4" />
      <div class="col-xs-12 mt-2 mb-2 d-flex flex-row">
        <div style="width: 90%">
          <label>Ordenar de</label>
          <select name="orderDirection" id="orderDirection" class="form-control" formControlName="orderDirection"
            (change)="listPaginated()">
            <option value="DESC">Mais antigos para mais novos</option>
            <option value="ASC">Mais novos para mais antigos</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</form>

<section>
  <app-analytics-card *ngFor="let sequence of result?.data" class="mb-2">
    <div class="row">
      <h3>Sequência da Automação: {{ sequence.campaign.name }} </h3>
    </div>
    <div class="row">
      <div class="col col-xs-12">
        <label>Última alteração:</label>
        <p>{{ sequence.updated_at | date:'dd/MM/yyyy' }}</p>
      </div>
      <div class="col col-xs-12">
        <label>Mensagem:</label>
        <p class="word-wrap" ngbTooltip="URL Original: {{ sequence.url }}">{{ getMessage(sequence) }}</p>

        <div *ngIf="sequence.short_url" class="url">
          <label>URL Original:</label>
          <a [href]="sequence.url" target="_blank" class="word-wrap">{{ sequence.url }}</a>
        </div>
      </div>
      <div class="col col-xs-12">
        <label>Usuário:</label>
        <p [innerHTML]="getUser(sequence)"></p>
      </div>
      <div class="col col-xs-12">
        <label>Status:</label>
        <p>{{ sequence.status_id | sequenceStatus }}</p>
      </div>
      <div class="col col-xs-12" *ngIf="!filterForm.get('pendingOnly').value">
        <label>Responsável aprovação:</label>
        <p>{{ sequence.reviewer ? sequence.reviewer.name : '-' }}</p>
      </div>
      <div class="col col-xs-12">
        <div class="row">
          <button type="button" class="btn col btn-primary mb-1 me-1" (click)="approve(sequence)"
            [disabled]="isPendingReview(sequence) == false">
            Aprovar
          </button>
          <button type="button" class="btn col btn-secondary" (click)="cancel(sequence)"
            [disabled]="isPendingReview(sequence) == false">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </app-analytics-card>
  <app-pagination *ngIf="result" [currentPage]="result.current_page" [itemsPerPage]="result.per_page"
    [totalItems]="result.total" (pageChanged)="pageChanged($event)"></app-pagination>
</section>

<ng-template #notFound>
  <tr *ngIf="!loading && user.validated">
    <td colspan="7" style="padding: 10px">Nenhum envio em massa para validação!</td>
  </tr>
</ng-template>

<ng-template #modalValidation let-modalValidation>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Cancelamento</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modalValidation.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-2">
          <label>Data do envio:</label>
          <p class="form-control">{{ sequence.updated_at| date:'dd/MM/yyyy' }}</p>
        </div>
        <div class="col">
          <label>Usuário:</label>
          <p class="form-control" [innerHTML]="getUser(sequence)"></p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Mensagem:</label>
          <p class="form-control">{{ getMessage(sequence) }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Justificativa:</label>
          <input type="text" class="form-control" [(ngModel)]="reason">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="disapprove()">Confirmar cancelamento</button>
      <button type="button" class="btn btn-outline-primary" (click)="modalValidation.dismiss()">Fechar</button>
    </div>
  </div>
</ng-template>