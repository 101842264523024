<div
  class="icone-conta"
  [class.global]="user.globalActivated"
  *ngIf="user.global && !isSmallDevice"
>
  <div *ngIf="user.globalActivated">
    <img src="assets/icone-mundo.png" alt="Ícone conta global" />
    <span>Conta global</span>
  </div>
  <div *ngIf="!user.globalActivated">
    <img src="assets/icone-brasil.png" alt="Ícone conta nacional" />
    <span> Conta nacional </span>
  </div>
</div>

<aside
  [ngClass]="{ show: isVisible, 'small-device': isSmallDevice }"
  [class.global]="user.globalActivated"
>
  <a class="logo" [routerLink]="['']" *ngIf="!isSmallDevice">
    <img src="/assets/logo-smsfunnel.png" alt="SMS Funnel" title="SMS Funnel" />
    <span>{{ title }}</span>
  </a>

  <div class="conta-small-device" *ngIf="user.global && isSmallDevice">
    <img
      src="assets/icone-mundo.png"
      alt="Ícone conta global"
      *ngIf="user.globalActivated"
      (click)="toggleGlobalAccount()"
    />
    <img
      src="assets/icone-brasil.png"
      alt="Ícone conta nacional"
      *ngIf="!user.globalActivated"
      (click)="toggleGlobalAccount()"
    />
  </div>

  <h3 *ngIf="user.simulated && !isSmallDevice">Simulando usuário</h3>

  <div class="conta-global" *ngIf="user.global && !isSmallDevice">
    <button
      type="button"
      class="btn btn-primary"
      (click)="toggleGlobalAccount()"
      ngbTooltip="{{
        user.globalActivated ? 'Ir para conta nacional' : 'Ir para conta global'
      }}"
    >
      <i
        class="fa"
        [class.fa-globe]="!user.globalActivated"
        [class.fa-brasil]="user.globalActivated"
      ></i>
      <span *ngIf="!isSmallDevice">
        Ir para Conta {{ user.globalActivated ? "Nacional" : "Global" }}
      </span>
    </button>
  </div>

  <ul>
    <li [ngClass]="{'admin-menu': true, 'menu-open': adminMenuVisible}" *ngIf="isAdministrator" data-testid="admin-area">
      <span (click)="toggleAdminMenu()">
        Admin
        <i class="fa" [class.fa-chevron-down]="!adminMenuVisible" [class.fa-chevron-up]="adminMenuVisible"></i>
      </span>
      <ul *ngIf="adminMenuVisible">
        <li>
          <i class="fa me-2 fa-tachometer"></i>
          <a href="{{ baseUrl }}/monitoring" target="_blank" class="section_image" data-testid="horizon" class="section_image" data-testid="horizon">Horizon</a>
        </li>
        <li>
          <i class="fa me-2 fa-fire"></i>
          <a href="{{ baseUrl }}/pulse" target="_blank" class="section_image" data-testid="pulse">Pulse</a>
        </li>
        <li routerLink="/blacklist" routerLinkActive="active" data-testid="blacklist">
          <i class="fa me-2 fa-adjust"></i>
          <span>Blacklist</span>
        </li>
        <li routerLink="/tutoriais" routerLinkActive="active" data-testid="tutoriais">
          <i class="fa me-2 fa-files-o"></i>
          <span>Tutoriais</span>
        </li>
      </ul>
    </li>
    <li
      *ngFor="let item of menuItens"
      [routerLink]="item.path"
      (click)="isSmallDevice && isVisible ? this.toggleMenu() : null"
      routerLinkActive="active"
    >
      <i class="fa me-2" *ngIf="item.icon" [class]="item.icon || 'fa-file'"></i>
      <img *ngIf="item.image" [src]="item.image" [alt]="item.text" class="section_image"/>
      <span *ngIf="isVisible">{{ item.text }}</span>
    </li>
    <a
      href="{{ supportLink }}?text=Olá, meu nome é {{
        user.name
      }} e estou com uma dúvida!"
      target="_blank"
      data-testid="support-area"
    >
      <li>
        <i class="fa me-2 fa-question-circle-o"></i>
        <span *ngIf="isVisible">Suporte</span>
      </li>
    </a>
  </ul>


</aside>

<div
  class="overlay"
  *ngIf="isVisible && isSmallDevice"
  (click)="toggleMenu()"
></div>
